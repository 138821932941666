import React from "react"
import Layout from "../../components/Layout"
import styled from "styled-components"
import RequestDemoForm from "../../components/individualProductPage/RequestDemoForm"
import StealBackground from "../../components/individualProductPage/images/steal-background.jpg"
import SpecBarMachineImage from "../../components/individualProductPage/images/XR_Page_Images/xr_profileview.png"
import UsaBackground from "../../components/individualProductPage/images/XR_Page_Images/handmade-usa.jpg"
import MachineOverviewImage from "../../components/individualProductPage/images/XR_Page_Images/xr_overview.jpg"
import HeavyDutyBackground from "../../components/individualProductPage/images/XR_Page_Images/heavy_duty_banner.jpg"
import MachineDimensionImage from "../../components/individualProductPage/images/xr-dims.jpg"
import RideOnTBTJ from "../../../static/FC-RIDER-YBTJ-LANDSCAPE.pdf"

//Main Images
import CylindricalMain1Image from "../../components/individualProductPage/Images/XR_Cylindrical/xr-v2-cyl-web1.jpg"
import CylindricalMain2Image from "../../components/individualProductPage/Images/XR_Cylindrical/xr-v2-cyl-web2.jpg"
import CylindricalMain3Image from "../../components/individualProductPage/Images/XR_Cylindrical/xr-v2-cyl-web3.jpg"
import CylindricalMain4Image from "../../components/individualProductPage/Images/XR_Cylindrical/xr-v2-cyl-web4.jpg"
import CylindricalMain5Image from "../../components/individualProductPage/Images/XR_Cylindrical/xr-v2-cyl-web5.jpg"
import DiskMain1Image from "../../components/individualProductPage/images/XR_Disk/xr-v2-disk-web1.jpg"
import DiskMain2Image from "../../components/individualProductPage/images/XR_Disk/xr-v2-disk-web2.jpg"
import DiskMain3Image from "../../components/individualProductPage/images/XR_Disk/xr-v2-disk-web3.jpg"
import DiskMain4Image from "../../components/individualProductPage/images/XR_Disk/xr-v2-disk-web5.jpg"
import MediaTile from "../../components/individualProductPage/MediaTile"
import MainSpecBar from "../../components/individualProductPage/FeaturedProduct/MainSpecBar"
import MadeInUSA from "../../components/individualProductPage/MadeInUSA"
import SectionTitle from "../../components/common/SectionTitle"
import MachineOverview from "../../components/individualProductPage/MachineOverview"
import HeavyDuty from "../../components/individualProductPage/HeavyDuty"
import MachineDimensions from "../../components/individualProductPage/MachineDimensions"
import '../product-page-styles.scss';

//End Main Images

import SEO from "../../components/SEO"
import Head from "../../components/Head"
import IndividualMachine from "../../components/individualProductPage/individualMachine"

const Padded = styled.div`
padding: 25px 0px;
`

const XrProductPage = () => {
  return (
    <>
      <Layout>
      <Padded>
        <SEO title="XR" />
        <Head title="XR v2.0" />
        <IndividualMachine
          displayOrbital="none"
          displayV2="none"
          cylindricalThumb1={CylindricalMain1Image}
          cylindricalThumb2={CylindricalMain2Image}
          cylindricalThumb3={CylindricalMain3Image}
          cylindricalThumb4={CylindricalMain4Image}
          cylindricalThumb5={CylindricalMain5Image}
          cylindricalMain1={CylindricalMain1Image}
          cylindricalMain2={CylindricalMain2Image}
          cylindricalMain3={CylindricalMain3Image}
          cylindricalMain4={CylindricalMain4Image}
          cylindricalMain5={CylindricalMain5Image}
          diskThumb1={DiskMain1Image}
          diskThumb2={DiskMain2Image}
          diskThumb3={DiskMain3Image}
          diskThumb4={DiskMain4Image}
          diskThumb5={DiskMain1Image}
          diskMain1={DiskMain1Image}
          diskMain2={DiskMain2Image}
          diskMain3={DiskMain3Image}
          diskMain4={DiskMain4Image}
          diskMain5={DiskMain1Image}
          brochureLink="/FC-XR-V2-ONE-SHEET.pdf"
          techLink="/FC XR V20 TECH SPEC.pdf"
          partsLink="/FC-XR-V2-P-EN.pdf"
          opLink="/FC-XR-V2-OP-EN.pdf"
          title="XR v2.0"
          textContent="If you are looking for a battery powered ride-on floor scrubber for cleaning large spaces quickly, the XR v2.0 is built for the task.  With large solution and recovery tanks, wide scrub path, and dual vacuum motors standard, this ride-on auto scrubber can clean large facilities, fast!"
          pageUrl=""
          specifications="Cleaning Path: 40-46 | Run Time: Up To 5.0 Hours | Tank Capacity: Sol: 65 Gal, Rec: 78 Gal"
          videoSource="https://www.youtube.com/embed/NaGoDJxcSGM"
          upLink="/ride-on-floor-scrubbers/gtr"
          machineName="XR v2.0"
          downLink="/ride-on-floor-scrubbers/gtr"
          displayUp="none"
          pmLink="/Rider-V2-PM.pdf"
          mtLink="https://www.dropbox.com/s/sx03160k2ppabfl/daily-weekly-maintenance-premium-v21306.pdf?dl=0"

        />

      <div className="tiles-container">
          <MediaTile
            title="Why FactoryCat ?"
            subTitle="Walk Behind Floor Scrubbers"
            link={RideOnTBTJ}
          />
          <MediaTile
            title="Videos"
            subTitle="Walk Behind Floor Scrubbers"
            link="/videos"
          />
          <MediaTile
            link="/images#ride-on-scrubbers"
            title="Images"
            subTitle="Walk Behind Floor Scrubbers"
          />
          <MediaTile
            title="Applications"
            subTitle="Walk Behind Floor Scrubbers"
            link="/applications"
          />
        </div>
        <MainSpecBar
          bgImage={StealBackground}
          machineImage={SpecBarMachineImage}
          myColor="white"
          machineName="XR v2.0"
          category="Ride On Floor Scrubber"
          path='40 - 46"'
          path2="(101.6 - 116.8 cm)"
          runTime=" Up To 5.0 Hours"
          solution="Solution: 65 Gal"
          solutionLiter="(246.1L)"
          recovery="Recovery: 78 Gal"
          recoveryLiter="(295.3L)"
        />
        {/* <ControllerImageContainer>
          <img src={ControllerImage} alt="" />
        </ControllerImageContainer> */}
        <MadeInUSA bgImage={UsaBackground} />

        <div className="spec-title-wrapper">
        <MachineOverview image={MachineOverviewImage} />
        </div>
        <HeavyDuty
          bgImage={HeavyDutyBackground}
          innerText="HEAVY DUTY CLEANING"
        />
        <MachineDimensions
          image={MachineDimensionImage}
          sizeTitle="Size (L x W x H):"
          size='77" × 43" × 59"'
          weightTitle="Weight (w/o batteries):"
          weight="1,200 - 1,250 lbs"
          voltageTitle="System Voltage:"
          voltage="36 VDC"
          stdBatteryTitle="Standard Battery:"
          battery="315 ah WET"
          powerTitle="Power:"
          power="3.0 hp"
          tankTitle="Tank Material:"
          tank='Poly 3/8"'
        />
        {/* <OptionsBar>Options</OptionsBar>
        <MiniHdOptions /> */}

        <div id="demo-form"></div>
        <div className="spec-title-wrapper">
        <SectionTitle title="Request a Demo" />
        </div>
        <RequestDemoForm image={CylindricalMain1Image} />
        </Padded>
      </Layout>
    </>
  )
}

export default XrProductPage
